@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

/* Scrollbar styling */
body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

body::-webkit-scrollbar-track {
  background: #d9d9d910;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #09090B !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MazzardH-ExtraBold";
  src: url("./assets/fonts/Mazzard/MazzardH-ExtraBold.otf");
  font-style: normal;
}

@font-face {
  font-family: "MazzardH-Black";
  src: url("./assets/fonts/Mazzard/MazzardH-Black.otf");
  font-style: normal;
}
